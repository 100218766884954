import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  async clearAll(): Promise<void> {
    return await Preferences.clear();
  }

  async clearKey(storageKey: string): Promise<void> {
    return await Preferences.remove({ key: storageKey });
  }

  async getArray(storageKey: string): Promise<string[]> {
    const storageResult = await Preferences.get({ key: storageKey });
    if (storageResult === null ||
      storageResult?.value === null ||
      storageResult?.value === undefined ||
      storageResult?.value === 'null' ||
      storageResult?.value === 'undefined'
    ) {
      return [];
    }

    return storageResult.value.split(',');
  }

  async getObject(storageKey: string): Promise<object | undefined> {
    const storageResult = await Preferences.get({ key: storageKey });
    if (storageResult === null ||
      storageResult?.value === null ||
      storageResult?.value === undefined ||
      storageResult?.value === 'null' ||
      storageResult?.value === 'undefined'
    ) {
      return undefined;
    }

    return JSON.parse(storageResult.value);
  }

  async getValue(storageKey: string): Promise<string> {
    const storageResult = await Preferences.get({ key: storageKey });
    if (storageResult === null ||
      storageResult?.value === null ||
      storageResult?.value === undefined ||
      storageResult?.value === 'null' ||
      storageResult?.value === 'undefined'
    ) {
      return '';
    }

    return storageResult.value;
  }

  async setArray(storageKey: string, newValue: unknown[]): Promise<void> {
    return Preferences.set({ key: storageKey, value: JSON.stringify(newValue) });
  }

  async setObject(storageKey: string, newValue: object): Promise<void> {
    return Preferences.set({ key: storageKey, value: JSON.stringify(newValue) });
  }

  async setValue(storageKey: string, newValue: string): Promise<void> {
    return Preferences.set({ key: storageKey, value: newValue });
  }
}
