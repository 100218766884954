import { computed, inject, Injectable, signal } from '@angular/core';
import { Constants } from '@app/constants';
import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { Event } from '@model/event';
import { EventSetting } from '@model/event-setting';
import { LiveParticipant, Participant } from '@model/participant';
import { Photo } from '@model/photo';
import { PushNotification } from '@model/push-notification';
import { AppData, AppEventSettings, AppSettings } from '@model/settings';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@service/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {

  // app/event settings
  appSettings = signal<AppSettings>(null);
  event = signal<Event>(null);
  eventSettings = signal<AppEventSettings>(null);

  // participants
  favorites = signal<Participant[]>(null);
  me = signal<Participant>(null);
  notifications = signal<PushNotification[]>(null);
  unreadNotifications = computed<PushNotification[]>(() => this.notifications().filter(n => n.isRead === false));

  // photos
  favoritePhotos = signal<Photo[]>(null);

  private appDataStorageKey = `${Constants.appVersionPrefix}.appData`;

  private storageService = inject(StorageService);
  private translateService = inject(TranslateService);

  async addNotifications(notifications: PushNotification[]): Promise<void> {
    if (notifications.length === 0) {
      return;
    }

    this.notifications.set([...this.notifications(), ...notifications]);
    await this.saveToStorage();
  }

  clearNotifications(): void {
    this.setNotifications([]);
  }

  async deleteNotification(notification: PushNotification): Promise<void> {
    this.notifications.update(notifications => notifications.filter(n => n.id !== notification.id));

    await this.saveToStorage();
  }

  async initialize(): Promise<void> {
    // await this.clearStorage();
    const appData = (await this.storageService.getObject(this.appDataStorageKey) ?? {}) as AppData;

    this.appSettings.set(appData.settings ?? new AppSettings({} as AppSettings));
    this.event.set(appData.event ?? null);
    this.eventSettings.set(appData.eventSettings ?? null);
    this.favorites.set(appData.favorites ?? []);
    this.me.set(appData.me ?? null);
    this.favoritePhotos.set(appData.favoritePhotos ?? []);
    this.notifications.set(appData.notifications ?? []);

    this.translateService.addLangs(['nl', 'en', 'fr']);
    const language = this.appSettings()?.appLanguage ?? 'nl';
    await this.setGlobalAppLanguage(language);
    console.log('Initialized appDataService');
  }

  async markNotificationAsRead(notification: PushNotification): Promise<void> {
    const index = this.notifications().findIndex(n => n.id === notification.id);
    if (index === -1) {
      return;
    }

    this.notifications.update(notifications => {
      notifications[index].isRead = true;

      return [...notifications];
    });
    await this.saveToStorage();
  }

  async setGlobalAppLanguage(language: string): Promise<void> {
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    this.appSettings.update(settings => ({
      ...settings,
      appLanguage: language,
    }));
    await this.saveToStorage();
  }

  async addUnreadPassageForFavorite(notifications: PushNotificationSchema[]): Promise<void> {
    const favorites = this.favorites();

    notifications.forEach(n => {
      let favoriteToUpdate: Participant = null;
      if (Capacitor.getPlatform() === 'android') {
        // Als de app op de voorgrond draait, dan staat het participant_uuid in data.participant_uuid
        // Als de app in de achtergrond draait en geactiveerd wordt, dan worden de open notifications uitgelezen en dan
        // staat het participant_uuid in de tag
        if (n.data.participant_uuid) {
          favoriteToUpdate = favorites.find(favorite => favorite.uuid === n.data.participant_uuid);
        } else {
          favoriteToUpdate = favorites.find(favorite => favorite.uuid === n.tag);
        }
      } else if (Capacitor.getPlatform() === 'ios') {
        favoriteToUpdate = favorites.find(favorite => favorite.uuid === n.data.participant_uuid);
      }
      if (favoriteToUpdate) {
        if (!Object.prototype.hasOwnProperty.call(favoriteToUpdate, 'unreadPassages')) {
          favoriteToUpdate.unreadPassages = [];
        }

        if (!favoriteToUpdate.unreadPassages.find(p => p.id === n.id)) {
          favoriteToUpdate.unreadPassages.push({ uuid: n.data.participant_uuid, text: n.data.text, id: n.id });
        }

        this.favorites.set([...favorites]);
      }
    });
  }

  async clearUnreadPassageForFavorite(_favorite: LiveParticipant): Promise<void> {
    this.favorites.update(favorites => {
      const favoriteToUpdate = favorites.find(favorite => favorite.uuid === _favorite.uuid);
      if (favoriteToUpdate) {
        favoriteToUpdate.unreadPassages = [];
      }

      return [...favorites];
    });
    await this.saveToStorage();
  }

  async clearStorage(): Promise<void> {
    await this.storageService.clearAll();
  }

  async setNotifications(notifications: PushNotification[]): Promise<void> {
    this.notifications.set(notifications);
    await this.saveToStorage();
  }

  async setPushToken(token: string): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      pushToken: token,
    }));
    await this.saveToStorage();
  }

  async setAllowParticipantTracking(allowParticipantTracking: boolean): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      allowParticipantTracking: allowParticipantTracking,
    }));
    await this.saveToStorage();
  }

  async setFavorites(favorieten: Participant[]): Promise<void> {
    this.favorites.set(favorieten);
    await this.saveToStorage();
  }

  async setFavoritePhotos(photos: Photo[]): Promise<void> {
    this.favoritePhotos.set(photos);
    await this.saveToStorage();
  }

  async setMe(me: Participant): Promise<void> {
    this.me.set(me);
    await this.saveToStorage();
  }

  async setShowLiveHelp(showHelp: boolean): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      showLiveHelp: showHelp,
    }));
    await this.saveToStorage();
  }

  async setSkipRegistration(value: boolean): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      skipRegistration: value,
    }));
    await this.saveToStorage();
  }

  async toggleAllowFollowerTracking(): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      allowFollowerTracking: !settings.allowFollowerTracking,
    }));
    await this.saveToStorage();
  }

  async toggleAllowParticipantTracking(): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      allowParticipantTracking: !settings.allowParticipantTracking,
    }));
    await this.saveToStorage();
  }

  async togglePushOnFavorite(value?: boolean): Promise<boolean> {
    if (value === undefined) {
      value = !this.appSettings().pushOnFavorite;
    }

    this.appSettings.update(settings => ({
      ...settings,
      pushOnFavorite: value,
    }));
    await this.saveToStorage();

    return value;
  }

  async togglePushOnNews(value?: boolean): Promise<boolean> {
    if (value === undefined) {
      value = !this.appSettings().pushOnNews;
    }

    this.appSettings.update(settings => ({
      ...settings,
      pushOnNews: value,
    }));

    await this.saveToStorage();

    return value;
  }

  async toggleSkipWelcomePage(): Promise<void> {
    this.appSettings.update(settings => ({
      ...settings,
      skipWelcomePage: !settings.skipWelcomePage,
    }));
    await this.saveToStorage();
  }

  async updateEventAndSettings(event: Event): Promise<void> {
    this.eventSettings.set(this.toAppEventSettings(event.settings));
    event.settings = []; // No longer needed after storing eventSettings from event

    this.event.set(event);
    await this.saveToStorage();
  }

  private toAppEventSettings(eventSettings: EventSetting[]): AppEventSettings {
    const settings = eventSettings.reduce((acc: Record<string, unknown>, { name, value }: EventSetting) => ({
      ...acc,
      [name]: value,
    }), {});

    return new AppEventSettings(settings);
  }

  private async saveToStorage(): Promise<void> {
    const appData: AppData = {
      settings: this.appSettings(),
      event: this.event(),
      eventSettings: this.eventSettings(),

      favorites: this.favorites(),
      favoritePhotos: this.favoritePhotos(),
      me: this.me(),
      notifications: this.notifications(),
    } as AppData;

    await this.storageService.setObject(this.appDataStorageKey, appData);
  }
}
