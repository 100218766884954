import { environment } from '@environment/environment';

export class MapSquare {
  northEast: { latitude: number, longitude: number };
  southWest: { latitude: number, longitude: number };

  constructor() {
    // Set the square initially to environment ad6Map
    this.northEast = {
      latitude: environment.ad6Map.northEast.latitude,
      longitude: environment.ad6Map.northEast.longitude,
    };
    this.southWest = {
      latitude: environment.ad6Map.southWest.latitude,
      longitude: environment.ad6Map.southWest.longitude,
    };
  }
}
