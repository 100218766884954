/* eslint-disable @typescript-eslint/dot-notation */
import { Constants } from '@app/constants';
import { Chapter } from '@model/chapter';
import { ContentItem } from '@model/content-item';
import { Event } from '@model/event';
import { GenericCardConfig } from '@model/generic-card';
import { MapSquare } from '@model/map-square';
import { Participant } from '@model/participant';
import { Photo } from '@model/photo';
import { PushNotification } from '@model/push-notification';

export class AppVersion {
  build: string;
  version: string;

  constructor(obj: AppVersion) {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj) as AppVersion;
    }
    this.build = obj.build || '';
    this.version = obj.version || '';
  }
}

export type MultiLanguageString = {
  'nl': string,
  'en': string,
  'fr': string,
};

export class AppVersionSetting {
  description: MultiLanguageString;
  title: MultiLanguageString;
  force: boolean;
  version: AppVersion; // Still here for backwards compatibility
  android: AppVersion;
  ios: AppVersion;

  constructor(obj: AppVersionSetting | string) {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj) as AppVersionSetting;
    }

    if (obj) {
      this.description = obj.description;
      this.title = obj.title;
      this.force = obj.force;
      this.version = obj.version;
      this.android = obj.android;
      this.ios = obj.ios;
    }
  }
}

export class AppContent {
  chapters: Chapter[];          // all content chapters except news
  newsItems: ContentItem[];     // all 'news' content items

  constructor() {
    this.chapters = null;
    this.newsItems = null;
  }
}

export class StoreUrlSetting {
  android: string;
  ios: string;

  constructor(obj: StoreUrlSetting) {
    if (typeof obj === 'string') {
      obj = JSON.parse(obj) as StoreUrlSetting;
    }

    if (obj) {
      this.android = obj.android;
      this.ios = obj.ios;
    }
  }
}

export class AppSettings {
  allowFavoritePush: boolean;
  allowFollowerTracking: boolean;
  allowNewsPush: boolean;
  allowParticipantTracking: boolean;
  appLanguage: string;
  isFollower: boolean;
  isParticipant: boolean;
  mapSquare: MapSquare;
  pushOnFavorite: boolean;
  pushOnNews: boolean;
  pushToken: string;
  pushTopicNews: string;
  pushTopicParticipantPrefix: string;
  showLiveHelp: boolean;
  skipRegistration: boolean;
  skipWelcomePage: boolean;

  constructor(obj: AppSettings) {
    this.allowFavoritePush = obj.allowFavoritePush ?? false;
    this.allowFollowerTracking = obj.allowFollowerTracking ?? false;
    this.allowNewsPush = obj.allowNewsPush ?? false;
    this.allowParticipantTracking = obj.allowParticipantTracking ?? false;
    this.appLanguage = obj.appLanguage ?? 'nl';
    this.isFollower = obj.isFollower ?? false;
    this.isParticipant = obj.isParticipant ?? false;
    this.mapSquare = obj.mapSquare ?? new MapSquare();
    this.pushOnFavorite = obj.pushOnFavorite ?? false;
    this.pushOnNews = obj.pushOnNews ?? true;
    this.pushTopicNews = obj.pushTopicNews ?? `${Constants.appVersionPrefix}.news`;
    this.pushTopicParticipantPrefix = obj.pushTopicParticipantPrefix ?? `${Constants.appVersionPrefix}.P.`;
    this.showLiveHelp = obj.showLiveHelp ?? false;
    this.skipRegistration = obj.skipRegistration ?? false;
    this.skipWelcomePage = obj.skipWelcomePage ?? false;
  }
}

export class AppEventSettings {
  appNotes: GenericCardConfig[];
  availableVersion: AppVersionSetting;
  enableParticipantLogic = false;
  isEventLive = false;
  isParticipantDataAvailable = false;
  isShowCountdown = false;
  livestreams: GenericCardConfig[];
  participantUrlPrefix: string;
  storeUrl: StoreUrlSetting;

  constructor(obj: object) {
    this.availableVersion = obj['availableVersion'] ? new AppVersionSetting(obj['availableVersion']) : undefined;
    this.appNotes = this.parseJson(obj['appNotes']) as GenericCardConfig[] ?? [];
    this.enableParticipantLogic = obj['enableParticipantLogic'] ? obj['enableParticipantLogic'] === 'true' : false;
    this.isEventLive = obj['isEventLive'] ? obj['isEventLive'] === 'true' : false;
    this.isParticipantDataAvailable = obj['isParticipantDataAvailable'] ? obj['isParticipantDataAvailable'] === 'true' : false;
    this.isShowCountdown = obj['isShowCountdown'] ? obj['isShowCountdown'] === 'true' : false;
    this.livestreams = this.parseJson(obj['livestreams']) as GenericCardConfig[] ?? [];
    this.participantUrlPrefix = obj['participantUrlPrefix'];
    this.storeUrl = new StoreUrlSetting(obj['storeUrl']);
  }

  private parseJson(obj: object | string): object | undefined {
    return obj && typeof obj === 'string' ? JSON.parse(obj) : obj;
  }
}

export type AppData = {
  settings: AppSettings,
  event: Event,
  eventSettings: AppEventSettings,

  favorites: Participant[],
  favoritePhotos: Photo[],
  me: Participant,
  notifications: PushNotification[],
};
